body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background: #f4f4f4;
  color: #333;
}

.App {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

nav {
  background: #333;
  color: #fff;
  padding: 10px 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 10px;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

nav a:hover {
  text-decoration: underline;
}